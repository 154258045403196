<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <p>
          <ValidationProvider v-slot="{errors, classes}"
                              :name="label"
                              :rules="rules">
            <sb-input :label="label"
                      :error="classes.invalid">
              <v-autocomplete v-model="selectedValue"
                              :loading="$wait.is('searchDomicileLoading')"
                              :items="domicileResults"
                              :search-input.sync="searchDomicileString"
                              :filter="customSearchDomicileFilter"
                              :readonly="readonly"
                              :clearable="true"
                              :error-messages="errors"
                              :error="classes.invalid"
                              item-text="fullname"
                              return-object
                              hide-details
                              hide-selected
                              dense
                              outlined
                              @input="domicileSelectedChange">
                <template #no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search ACB Domicile by
                      <strong>Bank Name or Domicile Code</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{item}">
                  <span>{{ item.fullname }}</span>
                </template>

                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.branch" />
                    <v-list-item-subtitle v-text="item.fullname" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <span class="field-details"
                    :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
            </sb-input>
          </validationprovider>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationProvider } from 'vee-validate';
import inputBase from '@/mixins/inputBase';

export default {
  components: {
    ValidationProvider,
  },
  mixins: [inputBase],
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },

    domicileDefaultValue: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      searchDomicileString: null,
      selectedDomicileResult: null,
      domicileResults: [],
    };
  },

  computed: {
    selectedValue: {
      get() {
        if (this.domicileDefaultValue) {
          return {
            fullname: this.domicileDefaultValue,
          };
        }
        return this.selectedDomicileResult;
      },
      set(newValue) {
        this.selectedDomicileResult = newValue;
      },
    },
  },

  watch: {
    async searchDomicileString(value) {
      // eslint-disable-next-line
      value && value !== this.selectedDomicileResult && (await this.getVehiclesDomicile(value.toUpperCase()));
    },
    domicileDefaultValue: {
      async handler(value) {
        if (value) {
          this.domicileResults = [{ fullname: value }];
        }
      },

      immediate: true,
    },
  },

  methods: {
    ...mapActions('vehicles', ['searchVehiclesDomiciles']),

    ...mapWaitingActions('vehicles', {
      searchVehiclesDomiciles: 'searchDomicileLoading',
    }),

    resetSelectedDomicile() {
      this.domicileResults = [];
      this.selectedDomicileResult = null;
      this.searchDomicileString = null;
    },
    customSearchDomicileFilter(item, queryText) {
      const branch = item.branch ? item.branch.toLowerCase() : '';
      const fullname = item.fullname ? item.fullname.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return branch.includes(searchText) || fullname.includes(searchText);
    },

    async domicileSelectedChange() {
      if (this.selectedValue !== null) {
        this.$emit('domicile-description', this.selectedDomicileResult);
      } else {
        this.domicileResults = [];
        this.selectedDomicileResult = null;
        this.searchDomicileString = null;
      }
    },

    async getVehiclesDomicile(value) {
      if (value.length > 3) {
        // this.$emit('vehicle-loading', true);
        const tempDomicileResults = await this.searchVehiclesDomiciles(value);
        if (tempDomicileResults !== null) {
          this.domicileResults = tempDomicileResults;
        }
      }
    },
  },
};
</script>
