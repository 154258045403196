<template>
  <v-row>
    <v-col cols="12"
           md="6">
      <v-row>
        <v-col cols="6">
          <sb-client-search rules="selected|required"
                            :client-default-value="customerInfomation.fleetAccountNumber"
                            label="Fleet Account Number"
                            @client="selectedClient"
                            @client-loading="onClientDetailsLoading" />
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-if="clientDetailsLoading"
               justify="center"
               class="mt-5 pt-5">
          <v-col cols="12"
                 sm="6">
            <sb-loader />
          </v-col>
        </v-row>
        <v-row v-if="showClientDetails && !clientDetailsLoading">
          <v-col cols="4"
                 sm="8">
            <sb-text-field v-model="customerInfomation.clientName"
                           disabled
                           label="Client Name" />
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-col>
  </v-row>
</template>
<script>
import SbClientSearch from '@/components/client/SbClientSearch';
import moment from 'moment';

import { CUSTOMER_ACCOUNT_TYPES } from '@/config/constants';

export default {
  components: {
    SbClientSearch,

  },
  props: {
    customerInfomationEntity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      customerInfomation: this.customerInfomationEntity,
      showClientDetails: false,
      clientDetailsLoading: false,
      accountTypes: [CUSTOMER_ACCOUNT_TYPES.PREPAID, CUSTOMER_ACCOUNT_TYPES.CREDIT],
    };
  },
  methods: {
    selectedClient(clientData) {
      if (clientData) {
        this.showClientDetails = true;
        this.customerInfomation.clientName = clientData.name;
        this.customerInfomation.fleetAccountNumber = clientData.id;
        this.customerInfomation.clientEffectiveDate = moment(clientData.effectiveDate, 'YYYYMMDD').format('YYYY-MM-DD');
        if (!clientData.prepaidClient || clientData.prepaidClient === CUSTOMER_ACCOUNT_TYPES.CREDIT.key) {
          this.customerInfomation.prepaidOrCredit = CUSTOMER_ACCOUNT_TYPES.CREDIT.key;
        } else {
          this.customerInfomation.prepaidOrCredit = clientData.prepaidClient;
        }
        this.$emit('client-selected', 'selected');
      } else {
        this.showClientDetails = false;
        this.$emit('client-selected', 'not-selected');
      }
    },
    onClientDetailsLoading(value) {
      this.clientDetailsLoading = value;
    },
  },
};
</script>
