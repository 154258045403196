<template>
  <div class="p-20">
    <v-form :disabled="!canUpdateVehicleCapture">
      <v-row>
        <v-col cols="4"
               sm="3">
          <sb-cost-billing-center-search v-model="vehicle.vehicleInformation.centreNumber"
                                         rules="selected|required"
                                         label="Cost/billing Center Number"
                                         :billing-centre-default-value="vehicle.vehicleInformation.centreNumber"
                                         :client-id="clientId"
                                         ref-name="billingCentre"
                                         @billing-centre="onBillingCentre" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"
               sm="6">
          <sb-vehicle-description-search v-model="vehicle.vehicleInformation.description"
                                         rules="selected|required"
                                         label="Vehicle Description"
                                         :vehicle-default-value="vehicle.vehicleInformation.description"
                                         ref-name="vehicleDescription"
                                         @vehicle-description="selectedVehicle" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"
               sm="3">
          <sb-select v-model="vehicle.vehicleInformation.vehicleMake"
                     :items="vehicleMakes"
                     label="Vehicle Make"
                     item-value="key"
                     item-text="value"
                     rules="required"
                     @change="loadVehicleModels(vehicle.vehicleInformation.vehicleMake)" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-input label="Vehicle Model">
            <v-autocomplete v-model="vehicle.vehicleInformation.vehicleModel"
                            :items="vehicleModels"
                            return-object
                            hide-details
                            hide-selected
                            dense
                            outlined
                            @change="selectedModelDescription(vehicle.vehicleInformation.vehicleModel)" />
          </sb-input>
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-select v-model="vehicle.vehicleInformation.vehicleYear"
                     :items="vehicleYears"
                     label="Year Model"
                     item-value="key"
                     item-text="value"
                     rules="required"
                     autocomplete />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-text-field v-model="vehicle.vehicleInformation.vehicleRegistrationNumber"
                         label="Registration Number"
                         rules="required"
                         :maxlength="9" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"
               sm="3">
          <sb-date-picker-field v-model="vehicle.vehicleInformation.vehicleRegistrationDate"
                                label="Registration Date"
                                ref-name="registrationDate"
                                rules="required"
                                :max="currentDate" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-date-picker-field v-model="vehicle.vehicleInformation.vehicleEffectiveDate"
                                label="Effective Date"
                                ref-name="effectiveDate"
                                rules="required"
                                :min="currentDate" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-select v-model="vehicle.vehicleInformation.vehicleCategory"
                     :items="vehicleCategories"
                     label="Vehicle Category"
                     item-value="key"
                     item-text="value"
                     rules="required" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-select v-model="vehicle.vehicleInformation.modelExtension"
                     label="Model Extension"
                     :items="modelExtensions" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"
               sm="3">
          <sb-text-field v-model="vehicle.vehicleInformation.transmission"
                         label="Transmission"
                         :maxlength="21"
                         disabled />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-select v-model="vehicle.vehicleInformation.colour"
                     :items="vehicleColours"
                     label="Vehicle Colour"
                     item-value="key"
                     item-text="value"
                     :rules="isVehicleColourRequired" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-numeric-field v-model="vehicle.vehicleInformation.cmanufactureFuelTankCapacity"
                            label="Manufacturer fuel tank capacity"
                            disabled />
        </v-col>
        <v-col cols="12"
               sm="3">
          <sb-numeric-field v-model="customFuelTank"
                            label="Custom Fuel Tank Capacity" />
          <span v-if="msg.customFuelTank"
                style="color:#d0021b">{{ msg.customFuelTank }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               sm="3">
          <sb-text-field v-model="vehicle.vehicleInformation.engineNumber"
                         label="Engine Number"
                         :maxlength="21" />
        </v-col>
        <v-col cols="12"
               sm="3">
          <sb-text-field v-model="vehicle.vehicleInformation.vinNumber"
                         label="Vin Number"
                         :maxlength="21" />
        </v-col>
        <v-col cols="12"
               sm="3">
          <sb-text-field v-model="vehicle.vehicleInformation.chasisNumber"
                         label="Chassis Number"
                         :maxlength="21" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-text-field v-model="vehicle.vehicleInformation.fuelType"
                         label="Fuel Type"
                         :maxlength="21"
                         disabled />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"
               sm="3">
          <sb-select v-model="vehicle.vehicleInformation.vehicleType"
                     label="Vehicle Type"
                     :items="vehicleTypes" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-select v-model="vehicle.vehicleInformation.requestType"
                     label="Request Type"
                     :items="requestTypes" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-text-field v-model="vehicle.vehicleInformation.vehicleGroup"
                         label="Vehicle Group"
                         :rules="isVehicleGroupRequired" />
        </v-col>
        <v-col cols="12"
               sm="3">
          <sb-text-field v-model="vehicle.vehicleInformation.vehicleId"
                         label="Vehicle ID"
                         :rules="isVehicleIdRequired" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"
               sm="3">
          <sb-select v-model="vehicle.vehicleInformation.rentalType"
                     label="Rental Type"
                     :items="rentalTypes" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               sm="3">
          <sb-select v-model="vehicle.vehicleInformation.warrantyExistence"
                     label="Warranty Existance "
                     :items="warrantyExistences"
                     rules="required"
                     @change="warrantyChange" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-date-picker-field v-model="vehicle.vehicleInformation.warrantyExpityDate"
                                label="Warranty Expiry Date "
                                ref-name="warrantyExpiryDate"
                                :min="currentDate"
                                :rules="warrantyRequired"
                                :disabled="disableWarranty" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-numeric-field v-model="vehicle.vehicleInformation.warrantyKilometers"
                            label="Warranty Kilometers"
                            type="number"
                            :rules="warrantyRequired"
                            :disabled="disableWarranty" />
          <span v-if="msg.warrantyKilometers"
                style="color:#d0021b">{{ msg.warrantyKilometers }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"
               sm="3">
          <sb-select v-model="vehicle.vehicleInformation.planType"
                     label="Plan Type"
                     :items="planTypes"
                     rules="required"
                     @change="planChange" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-date-picker-field v-model="vehicle.vehicleInformation.planExpiryDate"
                                label="Plan Expiry Date"
                                :min="currentDate"
                                ref-name="planExpiryDate"
                                :rules="planRequired"
                                :disabled="disablePlan" />
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-numeric-field v-model="vehicle.vehicleInformation.planKilometers"
                            label="Plan Kilometers"
                            type="number"
                            :rules="planRequired"
                            :disabled="disablePlan" />
          <span v-if="msg.planKilometers"
                style="color:#d0021b">{{ msg.planKilometers }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               sm="3">
          <sb-input label="COF Certificate" />
          <v-radio-group v-model="cofCertificate"
                         row
                         required
                         @change="cofCertificateChanged">
            <sb-radio-button label="Yes" />
            <sb-radio-button label="No" />
          </v-radio-group>
        </v-col>

        <v-col cols="12"
               sm="3">
          <sb-numeric-field v-model="vehicle.vehicleInformation.cofExpiryMonth"
                            label="COF Expiry Month"
                            max="12"
                            :rules="isCOFMonthRequired"
                            :disabled="disableCofExpiryMonth" />
          <span v-if="msg.cofExpiryMonth"
                style="color:#d0021b">{{ msg.cofExpiryMonth }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <sb-select v-model="vehicle.vehicleInformation.isNewOrUsed"
                     label="Vehicle New/Used"
                     :items="newVehicle"
                     rules="required"
                     @change="onNewOrUsedSelect" />
        </v-col>
      </v-row>

      <v-row v-if="newOrUsed">
        <v-col cols="12"
               sm="3">
          <sb-numeric-field v-model="openingOdometer"
                            label="Opening Odometer"
                            type="number"
                            :required="newOrUsed"
                            :maxlength="12"
                            @click="validateOpeningOdo()" />
          <span v-if="msg.openingOdometer"
                style="color:#d0021b">{{ msg.openingOdometer }}</span>
        </v-col>
        <v-col cols="12"
               sm="3">
          <sb-numeric-field v-model="startOdometer"
                            label="Start Odometer"
                            type="number"
                            :required="newOrUsed"
                            :maxlength="12"
                            @click="validateStartOdo()" />
          <span v-if="msg.startOdometer"
                style="color:#d0021b">{{ msg.startOdometer }}</span>
        </v-col>
      </v-row>
      <v-row v-if="newOrUsed">
        <v-col cols="12"
               sm="3">
          <sb-numeric-field v-model="lastService"
                            label="Last Service"
                            type="number"
                            :required="newOrUsed"
                            :maxlength="12" />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import SbCostBillingCenterSearch from '@/components/client/SbCostBillingCenterSearch';
import { mapActions } from 'vuex';
import SbVehicleDescriptionSearch from './SbVehicleDescriptionSearch';

export default {
  components: {
    SbCostBillingCenterSearch,
    SbVehicleDescriptionSearch,
  },
  props: {
    clientId: {
      type: String,
      default: undefined,
    },
    vehicleEntity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      vehicle: this.vehicleEntity,
      customiseFuelTank: false,
      newOrUsed: false,
      transimissions: ['Automatic', 'Manual'],
      fuelTypes: ['Diesel', 'Petrol'],
      vehicleGroups: [],
      vehicleTypes: [],
      vehicleMakes: [],
      vehicleYears: [],
      vehicleModels: [],
      vehicleModelDescription: [],
      vehicleDescriptions: [],
      vehicleCategories: [],
      requestTypes: [],
      rentalTypes: ['EXTERNAL RENTAL VEHICLE', 'INHOUSE RENTAL VEHICLE', 'UNAVAILABLE FOR RENTAL'],
      modelExtensions: [],
      vehicleColours: ['Black', 'Gray', 'Silver', 'Red', 'Blue', 'Brown', 'Green', 'Beige', 'Orange', 'Gold', 'Yellow', 'Purple'],
      newVehicle: ['', 'New', 'Used'],
      territories: ['', 'Rural', 'Urban'],
      currentDate: '',
      warrantyExistences: ['', 'Default', 'No Warranty', 'Warranty'],
      planTypes: ['', 'Maintenance Plan', 'None', 'Service Plan'],
      registrationRules: [
        (value) => !!value || 'Registration Number is required',
        (value) => /\s+/.test(value) || 'Registration Number must not contain any spaces',
      ],

      warrantyRequired: '',
      planRequired: '',
      customFuelTank: '',
      isVehicleColourRequired: '',
      isVehicleGroupRequired: '',
      isVehicleIdRequired: '',
      planKilometers: '',
      warrantyKilometers: '',
      openingOdometer: '',
      startOdometer: '',
      lastService: '',
      disablePlan: true,
      disableWarranty: true,
      disableCofExpiryMonth: true,
      isCOFMonthRequired: '',
      cofCertificate: '',
      msg: [],
    };
  },

  watch: {
    customFuelTank(value) {
      if (value > 0) {
        if (value <= this.vehicle.vehicleInformation.cmanufactureFuelTankCapacity) {
          this.msg.customFuelTank = 'Custom fuel tank must be larger than manufacturer fuel tank capacity';
        } else {
          this.msg.customFuelTank = '';
        }
      } else if (value < 0) {
        this.msg.customFuelTank = 'Custom fuel tank cannot be less than zero';
      } else if (value === 0) {
        this.msg.customFuelTank = '';
      }
      this.vehicle.vehicleInformation.customFuelTank = value;
    },

    lastService(value) {
      this.vehicle.vehicleInformation.lastService = value;
    },
    cofCertificate(value) {
      this.vehicle.vehicleInformation.cofCertificate = value;
    },

    startOdometer(value) {
      // true means used
      if (this.newOrUsed === true) {
        if (value <= 0) {
          this.msg.startOdometer = 'Start odometer has to be greater than zero';
        } else {
          this.msg.startOdometer = '';
        }
      }
      this.vehicle.vehicleInformation.startOdometer = this.startOdometer;
    },

    openingOdometer(value) {
      // true means used
      if (this.newOrUsed === true) {
        if (value <= 0) {
          this.msg.openingOdometer = 'Opening Odometer has to be greater than zero';
        } else {
          this.msg.openingOdometer = '';
        }
      }
      this.vehicle.vehicleInformation.openingOdometer = this.openingOdometer;
    },

    'vehicle.vehicleInformation.planKilometers': {
      handler() {
        if (this.vehicle.vehicleInformation.planType !== 'None' && this.vehicle.vehicleInformation.planType !== '') {
          if (this.vehicle.vehicleInformation.planKilometers === 0 || this.vehicle.vehicleInformation.planKilometers < 10000) {
            this.msg.planKilometers = 'Plan Kilometers cannot be less than 10000';
          } else {
            this.msg.planKilometers = '';
          }
          this.disablePlan = false;
        } else if (this.vehicle.vehicleInformation.planType === 'None') {
          this.msg.planKilometers = '';
          this.disablePlan = true;
        }
      },
    },

    'vehicle.vehicleInformation.warrantyKilometers': {
      handler() {
        if (this.vehicle.vehicleInformation.warrantyExistence !== 'No Warranty' && this.vehicle.vehicleInformation.warrantyExistence !== '') {
          if (this.vehicle.vehicleInformation.warrantyKilometers === 0 || this.vehicle.vehicleInformation.warrantyKilometers < 10000) {
            this.msg.warrantyKilometers = 'Warranty Kilometers cannot be less than 10000';
          } else {
            this.msg.warrantyKilometers = '';
          }
          this.disableWarranty = false;
        } else if (this.vehicle.vehicleInformation.warrantyExistence === 'No Warranty') {
          this.msg.warrantyKilometers = '';
          this.disableWarranty = true;
        }
      },
    },

    'vehicle.vehicleInformation.vehicleMake': {
      handler() {
        if (this.vehicle.vehicleInformation.description != null) {
          this.loadVehicleModels(this.vehicle.vehicleInformation.vehicleMake);
        }
      },
    },

    'vehicle.vehicleInformation.cofExpiryMonth': {
      handler(value) {
        if (this.cofCertificate === 0) {
          if (value > 0 && value < 13) {
            this.msg.cofExpiryMonth = '';
          } else {
            this.msg.cofExpiryMonth = 'COF Expiry Month is between 1 and 12';
          }
        }
      },
    },
  },

  mounted() {
    this.loadVehicleMakes();
    this.loadVehicleCategories();
    this.getCurrentDate();
    this.vehicleIndicators();
    this.loadRequestTypes();
    this.loadVehicleTypes();
    this.loadModelExtensions();
    this.loadVehicleModels(this.vehicle.vehicleInformation.vehicleMake);
    this.selectedModelDescription(this.vehicle.vehicleInformation.vehicleModel);
    this.checkUsed();
    this.checkCustomFuelTank();
    this.checkCofCert();
  },
  methods: {
    ...mapActions('vehicles', [
      'getVehicleMakes',
      'getVehicleModels',
      'getVehicleYears',
      'getVehicleDescriptions',
      'getGeneralCodes',
      'getFuelCapacity',
      'getVehiclesByMMCode',
      'searchVehiclesDescriptions',
    ]),
    ...mapActions('clients', ['getVehicleIndicators']),

    checkUsed() {
      if (this.vehicle.vehicleInformation.isNewOrUsed != null) {
        if (this.vehicle.vehicleInformation.isNewOrUsed === 'Used') {
          if (this.vehicle.vehicleInformation.lastService != null) {
            this.lastService = this.vehicle.vehicleInformation.lastService;
          }

          if (this.vehicle.vehicleInformation.openingOdometer != null) {
            this.openingOdometer = this.vehicle.vehicleInformation.openingOdometer;
          }

          if (this.vehicle.vehicleInformation.startOdometer != null) {
            this.startOdometer = this.vehicle.vehicleInformation.startOdometer;
          }
          this.newOrUsed = true;
        }
      }
    },
    checkCustomFuelTank() {
      if (this.vehicle.vehicleInformation.customFuelTank != null) {
        this.customFuelTank = this.vehicle.vehicleInformation.customFuelTank;
      }
    },

    validateOpeningOdo() {
      if (this.newOrUsed === true) {
        if (this.openingOdometer <= 0) {
          this.msg.openingOdometer = 'Opening odometer has to be greater than zero';
        } else {
          this.msg.openingOdometer = '';
        }
      }
    },

    validateStartOdo() {
      if (this.newOrUsed === true) {
        if (this.startOdometer <= 0) {
          this.msg.startOdometer = 'Start odometer has to be greater than zero';
        } else {
          this.msg.startOdometer = '';
        }
      }
    },

    cofCertificateChanged() {
      this.vehicle.vehicleInformation.cofExpiryMonth = 0;
      if (this.cofCertificate === 0) {
        this.vehicle.vehicleInformation.cofCertificate = 'Yes';
        this.disableCofExpiryMonth = false;
        this.msg.cofExpiryMonth = 'COF Expiry Month is required';

        this.isCOFMonthRequired = 'required';
      } else if (this.vehicle.vehicleInformation.cofCertificate === 1) {
        this.vehicle.cofCertificate = 'No';
        this.disableCofExpiryMonth = true;
        this.isCOFMonthRequired = '';
        this.msg.cofExpiryMonth = '';
      }
    },

    selectedVehicle(vehicleDetails) {
      if (vehicleDetails) {
        this.vehicle.vehicleInformation.vehicleMake = '';
        this.vehicle.vehicleInformation.description = '';

        this.vehicle.vehicleInformation.vehicleCategory = '';
        this.vehicle.vehicleInformation.modelExtension = '';
        this.vehicle.vehicleInformation.vehicleModel = '';
        this.vehicle.vehicleInformation.isn = '';

        this.vehicle.vehicleInformation.vehicleMake = vehicleDetails.manufactureCode;
        this.vehicle.vehicleInformation.description = vehicleDetails.description;

        this.vehicle.vehicleInformation.vehicleCategory = vehicleDetails.category;
        this.vehicle.vehicleInformation.modelExtension = vehicleDetails.extention;
        this.vehicle.vehicleInformation.vehicleModel = vehicleDetails.descriptionShort;
        this.vehicle.vehicleInformation.isn = vehicleDetails.isn;
        this.loadVehicleYear(this.vehicle.vehicleInformation.vehicleMake, this.vehicle.vehicleInformation.isn);

        this.getVehiclesByMMCodeF(vehicleDetails.manfModel);
      }
    },

    async getVehiclesSearch(value) {
      const tempvehicleResults = await this.searchVehiclesDescriptions(value);
      if (tempvehicleResults !== null) {
        this.vehicle.vehicleInformation.vehicleMake = tempvehicleResults[0].manufactureCode;
        this.vehicle.vehicleInformation.vehicleModel = tempvehicleResults[0].model;
        this.vehicle.vehicleInformation.vehicleModel = tempvehicleResults[0].descriptionShort;
        this.vehicle.vehicleInformation.description = tempvehicleResults[0].description;
        this.vehicle.vehicleInformation.vehicleCategory = tempvehicleResults[0].category;
        this.vehicle.vehicleInformation.modelExtension = tempvehicleResults[0].extention;
        this.vehicle.vehicleInformation.vehicleModel = tempvehicleResults[0].id;
        this.vehicle.vehicleInformation.isn = tempvehicleResults[0].isn;
        this.loadVehicleYear(this.vehicle.vehicleInformation.make, this.vehicle.vehicleInformation.isn);

        this.getVehiclesByMMCodeF(tempvehicleResults[0].manfModel);
      }
    },

    async loadVehicleMakes() {
      const tempVehicleMakes = await this.getVehicleMakes();

      if (tempVehicleMakes !== null) {
        for (let i = 0; i < tempVehicleMakes.vehicleMakes.length; i += 1) {
          this.vehicleMakes.push(tempVehicleMakes.vehicleMakes[i].make);
          this.vehicleMakes.sort();
        }
      } else {
        this.vehicleMakes = [];
      }
    },

    async vehicleIndicators() {
      const vehicleIndicators = await this.getVehicleIndicators(this.clientId);
      if (vehicleIndicators !== null) {
        if (vehicleIndicators.vehicleGroupRequired === 'Y') {
          this.isVehicleGroupRequired = 'required';
        } else {
          this.isVehicleGroupRequired = '';
        }

        if (vehicleIndicators.vehicleColour === 'Y') {
          this.isVehicleColourRequired = 'required';
        } else {
          this.isVehicleColourRequired = '';
        }

        if (vehicleIndicators.vehicleId === 'Y') {
          this.isVehicleIdRequired = 'required';
        } else {
          this.isVehicleIdRequired = '';
        }
      }
    },
    warrantyChange() {
      this.vehicle.vehicleInformation.warrantyExpityDate = '';
      this.vehicle.vehicleInformation.warrantyKilometers = 0;
      this.warrantyKilometers = 0;
      if (this.vehicle.vehicleInformation.warrantyExistence !== 'No Warranty' && this.vehicle.vehicleInformation.warrantyExistence !== '') {
        this.warrantyRequired = 'required';
        this.disableWarranty = false;
      } else {
        this.warrantyRequired = '';
        this.msg.warrantyKilometers = '';
        this.disableWarranty = true;
      }
    },

    planChange() {
      this.vehicle.vehicleInformation.planExpiryDate = '';
      this.vehicle.vehicleInformation.planKilometers = 0;
      this.planKilometers = 0;

      if (this.vehicle.vehicleInformation.planType !== 'None' && this.vehicle.vehicleInformation.planType !== '') {
        this.planRequired = 'required';
        this.disablePlan = false;
      } else {
        this.planRequired = '';
        this.msg.planKilometers = '';
        this.disablePlan = true;
      }
    },

    getCurrentDate() {
      const formattedDate = new Date().toJSON().slice(0, 10); // .replace(/-/g, '/');
      this.currentDate = formattedDate;
    },
    cleanRegistrationNumber() {
      return this.vehicle.vehicleInformation.vehicleRegistrationNumber.replace(/\s/g, '').join('');
    },
    async loadVehicleCategories() {
      const code = '072';
      const tempVehicleCategories = await this.getGeneralCodes(code);

      if (tempVehicleCategories !== null) {
        for (let i = 0; i < tempVehicleCategories.generalCodes.length; i += 1) {
          this.vehicleCategories.push(tempVehicleCategories.generalCodes[i].descriptionEnglish);
          this.vehicleCategories.sort();
        }
      } else {
        this.vehicleCategories = [];
      }
    },

    async loadVehicleTypes() {
      const code = '076';
      const tempVehicleCategories = await this.getGeneralCodes(code);

      if (tempVehicleCategories !== null) {
        for (let i = 0; i < tempVehicleCategories.generalCodes.length; i += 1) {
          this.vehicleTypes.push(tempVehicleCategories.generalCodes[i].descriptionEnglish);
          this.vehicleTypes.sort();
        }
      } else {
        this.vehicleTypes = [];
      }
    },

    async loadRequestTypes() {
      const code = '31';
      const tempVehicleCategories = await this.getGeneralCodes(code);

      if (tempVehicleCategories !== null) {
        for (let i = 0; i < tempVehicleCategories.generalCodes.length; i += 1) {
          this.requestTypes.push(tempVehicleCategories.generalCodes[i].description);
          this.requestTypes.sort();
        }
      } else {
        this.requestTypes = [];
      }
    },

    async loadModelExtensions() {
      const code = '123';
      const tempVehicleCategories = await this.getGeneralCodes(code);

      if (tempVehicleCategories !== null) {
        for (let i = 0; i < tempVehicleCategories.generalCodes.length; i += 1) {
          this.modelExtensions.push(tempVehicleCategories.generalCodes[i].description);
          this.modelExtensions.sort();
        }
      } else {
        this.modelExtensions = [];
      }
    },

    async getVehiclesByMMCodeF(mmCode) {
      const tempVehicles = await this.getVehiclesByMMCode(mmCode);

      // if (tempVehicles.mmCodeVehicles !== null) {
      //   for (let i = 0; i < tempVehicles.mmCodeVehicles.length; i += 1) {
      //     this.vehicleYears.push(tempVehicles.mmCodeVehicles[i].year);
      //     this.vehicleYears.sort();
      //   }
      // }

      if (tempVehicles.mmCodeVehicles[0].fuelTankSize > 0) {
        this.vehicle.vehicleInformation.cmanufactureFuelTankCapacity = tempVehicles.mmCodeVehicles[0].fuelTankSize;
      } else {
        this.vehicle.vehicleInformation.cmanufactureFuelTankCapacity = 0;
      }

      if (tempVehicles.mmCodeVehicles[0].transmission === 'A') {
        this.vehicle.vehicleInformation.transmission = 'Automatic';
      } else if (tempVehicles.mmCodeVehicles[0].transmission === 'M') {
        this.vehicle.vehicleInformation.transmission = 'Manual';
      } else if (tempVehicles.mmCodeVehicles[0].transmission === 'H') {
        this.vehicle.vehicleInformation.transmission = 'Hybrid';
      } else {
        this.vehicle.vehicleInformation.transmission = tempVehicles.mmCodeVehicles[0].transmission;
      }

      if (tempVehicles.mmCodeVehicles[0].fuelType === 'P') {
        this.vehicle.vehicleInformation.fuelType = 'Petrol';
      } else if (tempVehicles.mmCodeVehicles[0].fuelType === 'D') {
        this.vehicle.vehicleInformation.fuelType = 'Diesel';
      } else {
        this.vehicle.vehicleInformation.fuelType = tempVehicles.mmCodeVehicles[0].fuelType;
      }
    },

    async loadVehicleModels(make) {
      this.vehicleModels = [];
      this.vehicleYears = [];
      this.vehicleDescriptions = [];

      if (make !== '') {
        const tempVehicleModels = await this.getVehicleModels(make);

        if (tempVehicleModels !== null) {
          for (let i = 0; i < tempVehicleModels.vehicleModels.length; i += 1) {
            this.vehicleModels.push(tempVehicleModels.vehicleModels[i].model);
            this.vehicleModels.sort();
          }
        }
      } else {
        this.vehicleModels = [];
        this.vehicleYears = [];
      }
    },
    selectedModelDescription(model) {
      if (model !== '') {
        this.getVehiclesSearch(model);
      }
    },

    async loadVehicleYear(make, model) {
      this.vehicleYears = [];
      const payload = { make, model };
      const tempVehicleYears = await this.getVehicleYears(payload);
      if (tempVehicleYears !== null) {
        for (let i = 0; i < tempVehicleYears.modelYears.length; i += 1) {
          this.vehicleYears.push(tempVehicleYears.modelYears[i].year);
          this.vehicleYears.sort();
        }
      } else {
        this.vehicleYears = [];
      }
    },

    async loadVehicleVariant(make, model, year) {
      this.vehicleDescriptions = [];
      const payload = {
        make,
        model,
        year,
      };
      const tempVehicleDescriptions = await this.getVehicleDescriptions(payload);

      if (tempVehicleDescriptions !== null) {
        for (let i = 0; i < tempVehicleDescriptions.variants.length; i += 1) {
          this.vehicleDescriptions.push(tempVehicleDescriptions.variants[i].variant);
          this.vehicleDescriptions.sort();
        }
      } else {
        this.vehicleDescriptions = [];
      }
    },

    async loadFuelCapacity(make, model, variant, year) {
      const payload = {
        make,
        model,
        variant,
        year,
      };

      const tempFuelCapacity = await this.getFuelCapacity(payload);

      if (tempFuelCapacity) {
        if (tempFuelCapacity.fuelTankSize > 0) {
          this.vehicle.vehicleInformation.cmanufactureFuelTankCapacity = tempFuelCapacity.fuelTankSize;
        } else {
          this.vehicle.vehicleInformation.cmanufactureFuelTankCapacity = 0;
        }

        if (tempFuelCapacity.manualAuto === 'A') {
          this.vehicle.vehicleInformation.transmission = 'Automatic';
        } else if (tempFuelCapacity.manualAuto === 'M') {
          this.vehicle.vehicleInformation.transmission = 'Manual';
        } else {
          this.vehicle.vehicleInformation.transmission = tempFuelCapacity.manualAuto;
        }

        if (tempFuelCapacity.fuelType === 'P') {
          this.vehicle.vehicleInformation.fuelType = 'Petrol';
        } else if (tempFuelCapacity.fuelType === 'D') {
          this.vehicle.vehicleInformation.fuelType = 'Diesel';
        } else {
          this.vehicle.vehicleInformation.fuelType = tempFuelCapacity.fuelType;
        }
      }
    },

    onCustomiseFuelTankSelect(value) {
      if (value === 1) {
        this.vehicle.vehicleInformation.isCustomCapacityKnown = 'Yes';
      } else {
        this.vehicle.vehicleInformation.isCustomCapacityKnown = 'No';
      }

      this.customiseFuelTank = value === 0;
    },

    onNewOrUsedSelect(value) {
      this.newOrUsed = false;
      this.openingOdometer = 0;
      this.startOdometer = 0;
      this.lastService = 0;
      this.vehicle.vehicleInformation.openingOdometer = 0;
      this.vehicle.vehicleInformation.startOdometer = 0;
      this.vehicle.vehicleInformation.lastService = 0;

      this.msg.startOdometer = 'Start odometer has to be greater than zero';
      this.msg.openingOdometer = 'Opening odometer has to be greater than zero';
      this.msg.lastService = 'Last service odometer has to be greater than zero';

      if (value === 'New') {
        this.newOrUsed = false;
      } else {
        this.newOrUsed = true;
      }
    },
    canUpdateVehicleCapture() {
      return this.vehicle.requestStatus === ' In-Progress ';
    },
    onBillingCentre(selectedBillingCentre) {
      this.vehicle.vehicleInformation.centreNumber = selectedBillingCentre.hierarchyNumber;
    },
  },
};
</script>
