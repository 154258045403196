var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":_vm.centered ? 'center' : undefined}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_c('ValidationProvider',{attrs:{"name":_vm.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('sb-input',{attrs:{"label":_vm.label,"error":classes.invalid}},[_c('v-autocomplete',{attrs:{"loading":_vm.$wait.is('searchVehiclesDescriptionsLoading'),"items":_vm.vehicleResults,"search-input":_vm.searchVehicleString,"filter":_vm.customSearchVehicleFilter,"readonly":_vm.readonly,"clearable":!_vm.readonly,"error-messages":errors,"error":classes.invalid,"item-text":"id","return-object":"","cache-items":"","hide-details":"","hide-selected":"","dense":"","outlined":""},on:{"update:searchInput":function($event){_vm.searchVehicleString=$event},"update:search-input":function($event){_vm.searchVehicleString=$event},"input":_vm.vehicleSelectedChange},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for Vehile by "),_c('strong',[_vm._v("Description")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.id)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.description)}})],1)]}}],null,true),model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}}),_c('span',{staticClass:"field-details",class:{'error--text': errors.length > 0}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }