<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <p>
          <ValidationProvider v-slot="{errors, classes}"
                              :name="label"
                              :rules="rules">
            <sb-input :label="label"
                      :error="classes.invalid">
              <v-autocomplete v-model="selectedValue"
                              :loading="$wait.is('searchVehiclesDescriptionsLoading')"
                              :items="vehicleResults"
                              :search-input.sync="searchVehicleString"
                              :filter="customSearchVehicleFilter"
                              :readonly="readonly"
                              :clearable="!readonly"
                              :error-messages="errors"
                              :error="classes.invalid"
                              item-text="id"
                              return-object
                              cache-items
                              hide-details
                              hide-selected
                              dense
                              outlined
                              @input="vehicleSelectedChange">
                <template #no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for Vehile by
                      <strong>Description</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{item}">
                  <span>{{ item.id }}</span>
                </template>

                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.id" />
                    <v-list-item-subtitle v-text="item.description" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <span class="field-details"
                    :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
            </sb-input>
          </validationprovider>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationProvider } from 'vee-validate';
import inputBase from '@/mixins/inputBase';

export default {
  components: {
    ValidationProvider,
  },
  mixins: [inputBase],
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },

    vehicleDefaultValue: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      searchVehicleString: null,
      selectedVehicleResult: null,
      vehicleResults: [],
    };
  },

  computed: {
    selectedValue: {
      get() {
        if (this.vehicleDefaultValue) {
          return {
            id: this.vehicleDefaultValue,
          };
        }
        return this.selectedVehicleResult;
      },
      set(newValue) {
        this.selectedVehicleResult = newValue;
      },
    },
  },

  watch: {
    async searchVehicleString(value) {
      // eslint-disable-next-line
      value && value !== this.selectedVehicleResult && (await this.getVehiclesSearch(value.toUpperCase()));
    },
    vehicleDefaultValue: {
      async handler(value) {
        if (value) {
          this.vehicleResults = [{ id: value }];
        }
      },

      immediate: true,
    },
  },

  methods: {
    ...mapActions('vehicles', ['searchVehiclesDescriptions']),

    ...mapWaitingActions('vehicles', {
      searchVehiclesDescriptions: 'searchVehiclesDescriptionsLoading',
    }),

    customSearchVehicleFilter(item, queryText) {
      const makeShortDesc = item.id ? item.id.toLowerCase() : '';
      const makeDescription = item.description ? item.description.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return makeShortDesc.includes(searchText) || makeDescription.includes(searchText);
    },

    async vehicleSelectedChange() {
      this.$emit('vehicle-description', this.selectedVehicleResult);
    },

    async getVehiclesSearch(value) {
      if (value.length > 3) {
        // this.$emit('vehicle-loading', true);
        const tempvehicleResults = await this.searchVehiclesDescriptions(value);
        if (tempvehicleResults !== null) {
          this.vehicleResults = tempvehicleResults;
        }
        // this.$emit('vehicle-loading', false);
      }
    },
  },
};
</script>
