<template>
  <v-row justify="center">
    <v-dialog v-model="dialog"
              persistent
              scrollable
              max-width="1500px">
      <v-card>
        <v-toolbar color="#0033a1 !important"
                   dark>
          <v-tabs v-model="tab"
                  background-color="transparent"
                  color="#ffffff"
                  dark>
            <v-tab v-for="item in tabs"
                   :key="item"
                   :href="'#'+item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-btn icon
                 dark
                 left
                 @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in tabs"
                          :key="item"
                          :value="item">
                <v-row>
                  <template v-if="item === 'Vehicle Information'">
                    <sb-vehicle-information-form :client-id="clientId"
                                                 :vehicle-entity.sync="vehicle" />
                  </template>

                  <template v-if="item === 'Product Setup'">
                    <sb-product-setup-form :client-id="clientId"
                                           :vehicle-entity.sync="vehicle" />
                  </template>

                  <template v-if="item === 'Driver Information'">
                    <sb-driver-information-form :client-id="clientId"
                                                :vehicle-entity.sync="vehicle" />
                  </template>

                  <template v-if="item === 'Card Delivery Instruction'">
                    <sb-card-delivery-instruction-form :client-id="clientId"
                                                       :vehicle-entity.sync="vehicle" />
                  </template>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary"
                 depressed
                 @click="back()">
            Back
          </v-btn>
          <v-btn v-if="!isFormComplete"
                 color="secondary"
                 depressed
                 @click="next()">
            Next
          </v-btn>
          <v-btn v-if="isFormComplete"
                 color="success"
                 depressed
                 :disabled="isSaveButtonDisabled"
                 @click="addVehicle">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import SbVehicleInformationForm from '@/components/vehicleCapture/SbVehicleInformationForm';
import SbProductSetupForm from '@/components/vehicleCapture/SbProductSetupForm';
import SbDriverInformationForm from '@/components/vehicleCapture/SbDriverInformationForm';
import SbCardDeliveryInstructionForm from '@/components/vehicleCapture/SbCardDeliveryInstructionForm';
import Vehicle from '@/models/vehicleCapture/Vehicle';

export default {
  components: {
    SbVehicleInformationForm,

    SbProductSetupForm,
    SbDriverInformationForm,

    SbCardDeliveryInstructionForm,
  },
  props: {
    clientId: {
      type: String,
      default: undefined,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    vehicleEntity: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      vehicle: new Vehicle(),
      tab: null,
      tabs: [
        'Vehicle Information',
        // 'Vehicle Setup',
        'Product Setup',
        'Driver Information',
        // 'Maintenance Contract Setup',
        'Card Delivery Instruction',
      ],

      isDisabled: false,
      isSaveButtonDisabled: false,
    };
  },

  computed: {
    dialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isFormComplete() {
      const indexOfCurrentTab = this.tabs.indexOf(this.tab);
      return indexOfCurrentTab + 1 === this.tabs.length;
    },
  },

  watch: {
    vehicleEntity: {
      handler(value) {
        if (value.vehicleInformation !== undefined) {
          this.vehicle = this.vehicleEntity;
        }
      },
      immediate: true,
    },

    'vehicle.vehicleInformation.vehicleRegistrationNumber': {
      handler(value) {
        if (value !== undefined || value !== '') {
          this.isSaveButtonDisabled = false;
        } else {
          this.isSaveButtonDisabled = true;
        }
      },
    },

    'vehicle.vehicleInformation.centreNumber': {
      handler(value) {
        if (value !== undefined || value !== '') {
          this.isSaveButtonDisabled = false;
        } else {
          this.isSaveButtonDisabled = true;
        }
      },
    },
  },

  methods: {
    resetForm() {
      this.tab = this.tabs[0].toString();
      this.dialog = false;

      this.vehicle = new Vehicle();
    },
    closeDialog() {
      this.tab = this.tabs[0].toString();
      this.dialog = false;
    },

    uploadTemplate() {
      // TODO: implement banking details upload
    },

    next() {
      const indexOfCurrentTab = this.tabs.indexOf(this.tab);
      if (indexOfCurrentTab + 1 !== this.tabs.length) {
        this.tab = this.tabs[indexOfCurrentTab + 1];
      }
    },

    back() {
      const indexOfCurrentTab = this.tabs.indexOf(this.tab);
      if (indexOfCurrentTab !== 0) {
        this.tab = this.tabs[indexOfCurrentTab - 1];
      }
    },
    addVehicle() {
      this.validateVehicle();
      this.$emit('vehicle-item', this.vehicle);

      this.resetForm();
    },
    validateVehicle() {
      this.vehicle.validationErrors = [];

      if (
        this.vehicle.vehicleInformation.centreNumber.length < 3 ||
        this.vehicle.vehicleInformation.centreNumber === '' ||
        this.vehicle.vehicleInformation.centreNumber === undefined
      ) {
        this.vehicle.validationErrors.push('Centre Number is required');
      }

      if (
        this.vehicle.vehicleInformation.vehicleRegistrationNumber === undefined ||
        this.vehicle.vehicleInformation.vehicleRegistrationNumber === ''
      ) {
        this.vehicle.validationErrors.push('Registration Number is required');
      }

      if (this.vehicle.vehicleInformation.vehicleRegistrationDate === undefined || this.vehicle.vehicleInformation.vehicleRegistrationDate === '') {
        this.vehicle.validationErrors.push('Registration Date is required');
      }

      if (this.vehicle.vehicleInformation.vehicleEffectiveDate === undefined || this.vehicle.vehicleInformation.vehicleEffectiveDate === '') {
        this.vehicle.validationErrors.push('Effective Date Date is required');
      }

      if (this.vehicle.vehicleInformation.vehicleMake === undefined || this.vehicle.vehicleInformation.vehicleMake === '') {
        this.vehicle.validationErrors.push('Make is required');
      }

      if (this.vehicle.vehicleInformation.vehicleModel === undefined || this.vehicle.vehicleInformation.vehicleModel === '') {
        this.vehicle.validationErrors.push('Model is required');
      }

      if (this.vehicle.vehicleInformation.vehicleYear === undefined || this.vehicle.vehicleInformation.vehicleYear === '') {
        this.vehicle.validationErrors.push('Year Model is required');
      }

      if (this.vehicle.vehicleInformation.description === undefined || this.vehicle.vehicleInformation.description === '') {
        this.vehicle.validationErrors.push('Vehicle description is required');
      }

      if (this.vehicle.vehicleInformation.vehicleCategory === undefined || this.vehicle.vehicleInformation.vehicleCategory === '') {
        this.vehicle.validationErrors.push('Category is required');
      }

      if (this.vehicle.productSetup.primaryCardType === undefined || this.vehicle.productSetup.primaryCardType === '') {
        this.vehicle.validationErrors.push('Card  is required');
      }
      if (this.vehicle.productSetup.adminFeeGroup === undefined || this.vehicle.productSetup.adminFeeGroup === '') {
        this.vehicle.validationErrors.push('Admin Fee   is required');
      }
      if (this.vehicle.productSetup.truckFuelNet === undefined || this.vehicle.productSetup.truckFuelNet === '') {
        this.vehicle.validationErrors.push('truckfuelnet Indicator  is required');
      }
      if (this.vehicle.productSetup.oilCompanyCard === undefined || this.vehicle.productSetup.oilCompanyCard === '') {
        this.vehicle.validationErrors.push('Oil company card Indicator is required');
      }
      if (this.vehicle.productSetup.bdfleetMoveRfid === undefined || this.vehicle.productSetup.bdfleetMoveRfid === '') {
        this.vehicle.validationErrors.push('bp fleet move rfid indicator is required');
      }
      if (this.vehicle.productSetup.bdfleetMoveRfidsNamibia === undefined || this.vehicle.productSetup.bdfleetMoveRfidsNamibia === '') {
        this.vehicle.validationErrors.push('bluefuel rfid-Namibia Indicator is required');
      }
      if (this.vehicle.productSetup.clientSubsidy === undefined || this.vehicle.productSetup.clientSubsidy === '') {
        this.vehicle.validationErrors.push('client Subsidiser Indicator is required');
      }
      if (this.vehicle.productSetup.oilSubsidy === undefined || this.vehicle.productSetup.oilSubsidy === '') {
        this.vehicle.validationErrors.push('oil co subsidiser Indicator is required');
      }
      if (this.vehicle.productSetup.walletRequired === undefined || this.vehicle.productSetup.walletRequired === '') {
        this.vehicle.validationErrors.push('wallet Required Indicator is required');
      }
      if (this.vehicle.productSetup.smashGrab === undefined || this.vehicle.productSetup.smashGrab === '') {
        this.vehicle.validationErrors.push('smash and Grab  Indicator is required');
      }
      if (this.vehicle.productSetup.ignoreFixedCost === undefined || this.vehicle.productSetup.ignoreFixedCost === '') {
        this.vehicle.validationErrors.push('ignore Fixed Costs? Indicator is required');
      }
      if (this.vehicle.productSetup.ignoreOdometer === undefined || this.vehicle.productSetup.ignoreOdometer === '') {
        this.vehicle.validationErrors.push('ignore Odometer?  Indicator is required');
      }
      if (this.vehicle.productSetup.driverToPayInHouse === undefined || this.vehicle.productSetup.driverToPayInHouse === '') {
        this.vehicle.validationErrors.push('driver To Pay InHouse Indicator is required');
      }
      if (this.vehicle.productSetup.budgetOption === undefined || this.vehicle.productSetup.budgetOption === '') {
        this.vehicle.validationErrors.push('budget Option Indicator is required');
      }
      if (this.vehicle.productSetup.fraudOdo === undefined || this.vehicle.productSetup.fraudOdo === '') {
        this.vehicle.validationErrors.push('fraud Odo  Indicator is required');
      }
      if (this.vehicle.productSetup.transactionLimit === undefined || this.vehicle.productSetup.transactionLimit === '') {
        this.vehicle.validationErrors.push('transaction Limit is required');
      }
      if (this.vehicle.productSetup.merchantLimit === undefined || this.vehicle.productSetup.merchantLimit === '') {
        this.vehicle.validationErrors.push('merchant Limit is required');
      }

      if (this.vehicle.driverInformation.driverAllowance === 0 || this.vehicle.driverInformation.driverAllowance === 1) {
        // Driver Validations - 0 is for Yes, 1 is for No
        if (this.vehicle.driverInformation.driverAllowance === 0) {
          // Do driver validations here
          if (this.vehicle.driverInformation.countryCode === undefined || this.vehicle.driverInformation.countryCode === '') {
            this.vehicle.validationErrors.push('Cellphone Country Code ID is required');
          }

          if (this.vehicle.driverInformation.cellphoneNumber === undefined || this.vehicle.driverInformation.cellphoneNumber === '') {
            this.vehicle.validationErrors.push('Cellphone number is required');
          }

          // do the rest of validations here (for driver allowance only)
        }
      } else {
        this.vehicle.validationErrors.push('Driver Allowance is required');
      }

      if (this.vehicle.validationErrors.length > 0) {
        this.vehicle.hasErrors = true;
      } else {
        this.vehicle.hasErrors = false;
      }
      return this.vehicle;
    },
  },
};
</script>
